import firebase from "firebase/app"
import Rio from "@retter/sdk"
import Cookies from "js-cookie"

import loadFirebase from "../lib/db"
import {
    mp
} from '../helpers/masterPass'
import {
    sbSignIn
} from '../helpers/sbSignIn'
import {
    sbSignUp
} from '../helpers/sbSignUp'
import {
    sbSAnswerChallenge
} from '../helpers/sbAnswerChallenge'
import {
    sbForgotPassword
} from '../helpers/sbForgotPassword'
import {
    sbCreateSbCard
} from '../helpers/sbCreateSbCard'
import {
    sbAddSbCard
} from '../helpers/sbAddSbCard'
import {
    sbRenewSbCard
} from '../helpers/sbRenewSbCard'
import {
    sbUpdateProfile
} from '../helpers/sbUpdateProfile'
import {
    sbUpdateFavorite
} from '../helpers/sbUpdateFavorite'
import {
    sbManageAutoReload
} from '../helpers/sbManageAutoReload'

import {
    sbUserAnswerChallenge
} from "../helpers/sbUserAnswerChallenge"

import {
    sbResendConfirmationCode
} from "../helpers/sbResendConfirmationCode"

import {
    sbRequest
} from "../helpers/sbRequest"
import getConfig from 'next/config'
import { getRioInstance, rioAnswerChallange, rioGetUserInfo, rioUpdatePassword, ticimaxSignUp } from "../helpers/rioHelper"
import { isProd } from "../helpers/utility"
const {
    serverRuntimeConfig,
    publicRuntimeConfig
} = getConfig()

const LOGOUT = 'auth/LOGOUT'
const VALUE_CHANGED = 'auth/VALUE_CHANGED'
const NEW_USER_VALUE_CHANGED = 'auth/NEW_USER_VALUE_CHANGED'
const CHALLENGE_CHANGED = 'auth/CHALLENGE_CHANGED'
const CLEAR_NEW_USER = 'auth/CLEAR_NEW_USER'

const GET_MASTERPASS_TOKEN = 'auth/GET_MASTERPASS_TOKEN'
const GET_MASTERPASS_TOKEN_SUCCESS = 'auth/GET_MASTERPASS_TOKEN_SUCCESS'

const GET_MASTERPASS_TOKEN_AMOUNT = 'auth/GET_MASTERPASS_TOKEN_AMOUNT'
const GET_MASTERPASS_TOKEN_AMOUNT_SUCCESS = 'auth/GET_MASTERPASS_TOKEN_AMOUNT_SUCCESS'

const SIGN_IN_SB = 'auth/SIGN_IN_SB'
const SIGN_IN_SB_SUCCESS = 'auth/SIGN_IN_SB_SUCCESS'
const SIGN_IN_SB_FAIL = 'auth/SIGN_IN_SB_FAIL'

const RESET_PASSWORD_SB = 'auth/RESET_PASSWORD_SB'
const RESET_PASSWORD_SB_SUCCESS = 'auth/RESET_PASSWORD_SB_SUCCESS'
const RESET_PASSWORD_SB_FAIL = 'auth/RESET_PASSWORD_SB_FAIL'

const SIGN_UP_SB = 'auth/SIGN_UP_SB'
const SIGN_UP_SB_SUCCESS = 'auth/SIGN_UP_SB_SUCCESS'
const SIGN_UP_SB_FAIL = 'auth/SIGN_UP_SB_FAIL'

const ANSWER_CHALLENGE = 'auth/ANSWER_CHALLENGE'
const ANSWER_CHALLENGE_SUCCESS = 'auth/ANSWER_CHALLENGE_SUCCESS'
const ANSWER_CHALLENGE_FAIL = 'auth/ANSWER_CHALLENGE_FAIL'

const USER_ANSWER_CHALLENGE = 'auth/USER_ANSWER_CHALLENGE'
const USER_ANSWER_CHALLENGE_SUCCESS = 'auth/USER_ANSWER_CHALLENGE_SUCCESS'
const USER_ANSWER_CHALLENGE_FAIL = 'auth/USER_ANSWER_CHALLENGE_FAIL'

const RESEND_CONFIRMATION_CODE = 'auth/RESEND_CONFIRMATION_CODE'
const RESEND_CONFIRMATION_CODE_SUCCESS = 'auth/RESEND_CONFIRMATION_CODE_SUCCESS'
const RESEND_CONFIRMATION_CODE_FAIL = 'auth/RESEND_CONFIRMATION_CODE_FAIL'

const CREATE_SBCARD = 'auth/CREATE_SBCARD'
const CREATE_SBCARD_SUCCESS = 'auth/CREATE_SBCARD_SUCCESS'
const CREATE_SBCARD_FAIL = 'auth/CREATE_SBCARD_FAIL'

const ADD_SBCARD = 'auth/ADD_SBCARD'
const ADD_SBCARD_SUCCESS = 'auth/ADD_SBCARD_SUCCESS'
const ADD_SBCARD_FAIL = 'auth/ADD_SBCARD_FAIL'

const RENEW_SBCARD = 'auth/RENEW_SBCARD'
const RENEW_SBCARD_SUCCESS = 'auth/RENEW_SBCARD_SUCCESS'
const RENEW_SBCARD_FAIL = 'auth/RENEW_SBCARD_FAIL'

const UPDATE_PROFILE = 'auth/UPDATE_PROFILE'
const UPDATE_PROFILE_SUCCESS = 'auth/UPDATE_PROFILE_SUCCESS'
const UPDATE_PROFILE_FAIL = 'auth/UPDATE_PROFILE_FAIL'

const UPDATE_FAVORITE = 'auth/UPDATE_FAVORITE'
const UPDATE_FAVORITE_SUCCESS = 'auth/UPDATE_FAVORITE_SUCCESS'
const UPDATE_FAVORITE_FAIL = 'auth/UPDATE_FAVORITE_FAIL'

const CREATE_PIN = 'auth/CREATE_PIN'
const CREATE_PIN_SUCCESS = 'auth/CREATE_PIN_SUCCESS'
const CREATE_PIN_FAIL = 'auth/CREATE_PIN_FAIL'

const UPDATE_PIN = 'auth/UPDATE_PIN'
const UPDATE_PIN_SUCCESS = 'auth/UPDATE_PIN_SUCCESS'
const UPDATE_PIN_FAIL = 'auth/UPDATE_PIN_FAIL'

const FORGOT_PIN = 'auth/FORGOT_PIN'
const FORGOT_PIN_SUCCESS = 'auth/FORGOT_PIN_SUCCESS'
const FORGOT_PIN_FAIL = 'auth/FORGOT_PIN_FAIL'

const DISABLE_PIN = 'auth/DISABLE_PIN'
const DISABLE_PIN_SUCCESS = 'auth/DISABLE_PIN_SUCCESS'
const DISABLE_PIN_FAIL = 'auth/DISABLE_PIN_FAIL'

const AUTO_RELOAD = 'auth/AUTO_RELOAD'
const AUTO_RELOAD_SUCCESS = 'auth/AUTO_RELOAD_SUCCESS'
const AUTO_RELOAD_FAIL = 'auth/AUTO_RELOAD_FAIL'

let unsubscribe = null

const initialState = {
    //firebaseToken:"",
    revokeTime: "",
    isAnonym: true,
    uid: "",
    masterpassToken: "",
    errorMessage: "",
    //masterpass data
    masterpassRegisterResponse: "",
    masterpassLinkResponse: "",
    masterpassCardsList: [],
    registerMasterPassSuccess: false,
    //masterpassRegisterState:"register"  //"register" , "otp"
    challengeInputType: "",
    challengeId: "",
    challengeMessage: "",
    challengeCode: "",
    stage: "",
    message: "",
    userId: "",
    challenge: null,
    newUser: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        allowEmail: false,
        allowSms: false,
        //tckn: "",
        //gender: "",
        preferredName: "",
    }
}


export default (state = initialState, action) => {
    switch (action.type) {

        case VALUE_CHANGED: {
            return {
                ...state,
                [action.key]: action.value
            }
        }
        case NEW_USER_VALUE_CHANGED: {
            return {
                ...state,
                newUser: {
                    ...state.newUser,
                    [action.key]: action.value
                }
            }
        }

        case CLEAR_NEW_USER: {
            return {
                ...state,
                newUser: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    password: "",
                    allowEmail: false,
                    allowSms: false,
                    preferredName: "",
                }
            }
        }

        case CHALLENGE_CHANGED: {
            return {
                ...state,
                challengeId: action.data.challengeId,
                challengeCode: action.data.code,
                message: action.data.message,
                challengeInputType: action.data.challengeInputType,
                userId: action.data.userId,
                challengeMessage: action.data.challengeMessage,
                stage: action.data.stage,
            }
        }

        case GET_MASTERPASS_TOKEN_SUCCESS: {
            return {
                ...state,
                masterpassToken: action.data.data.token,
            }
        }
        case GET_MASTERPASS_TOKEN_AMOUNT_SUCCESS: {
            return {
                ...state,
                masterpassToken: action.data.data.token,
            }
        }

        case LOGOUT: {
            if (unsubscribe) {
                unsubscribe()
            }
            return {
                ...state,
            }
        }

        case SIGN_IN_SB_SUCCESS: {
            return {
                ...state

            }
        }
        case SIGN_IN_SB_FAIL: {
            return {
                ...state
            }
        }
        case SIGN_UP_SB_SUCCESS: {
            return {
                ...state
            }
        }
        case RESET_PASSWORD_SB_SUCCESS: {
            return {
                ...state
            }
        }
        case ANSWER_CHALLENGE_SUCCESS: {
            return {
                ...state
            }
        }
        case ANSWER_CHALLENGE_FAIL: {
            return {
                ...state
            }
        }
        default:
            return state
    }
}

const valueChanged = (key, value) => {
    return {
        type: VALUE_CHANGED,
        key,
        value,
    }
}

const newUserValueChanged = (key, value) => {
    return {
        type: NEW_USER_VALUE_CHANGED,
        key,
        value,
    }
}

const clearNewUser = (key, value) => {
    return {
        type: CLEAR_NEW_USER,
    }
}

const clearChallange = () => {
    return {
        type: CHALLENGE_CHANGED,
        data: {
            challengeCode: "",
            challengeId: "",
            challengeMessage: "",
            challengeInputType: "",
            userId: "",
            message: "",
            stage: '',
        }
    }
}

const logout = () => {
    return {
        type: LOGOUT
    }
}

const getMasterPassToken = () => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: GET_MASTERPASS_TOKEN,
            })
            mp({
                dispatch
            })
                .then(function (data) {
                    dispatch({
                        type: GET_MASTERPASS_TOKEN_SUCCESS,
                        data
                    })
                    resolve(data)
                })
                .catch(function (err) {
                    reject(err)
                })
        })
    }
}

const getMasterPassTokenAmount = () => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: GET_MASTERPASS_TOKEN_AMOUNT,
            })
            let cardObject = {}
            if (getState().user.selectedPaymentMethod) {
                cardObject = {
                    "cardId": getState().user.selectedPaymentMethod.UniqueId,
                    "maskedCardNumber": getState().user.selectedPaymentMethod.Value1,
                    "cardName": getState().user.selectedPaymentMethod.Name,
                    "bankIca": getState().user.selectedPaymentMethod.BankIca,
                    "eftCode": getState().user.selectedPaymentMethod.EftCode,
                    "productName": getState().user.selectedPaymentMethod.ProductName,
                    "loyaltyCode": getState().user.selectedPaymentMethod.LoyaltyCode,
                }
            }
            mp({
                token: getState().auth.token,
                amount: getState().cart.amount,
                cardObject,
            })
                .then(function (data) {
                    dispatch({
                        type: GET_MASTERPASS_TOKEN_AMOUNT_SUCCESS,
                        data
                    })
                    resolve(data)
                })
                .catch(function (err) {
                    reject(err)
                })
        })
    }
}

const signIn = (emailOrPhone, password, otp) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SIGN_IN_SB,
            })
            let isEmail = emailOrPhone.indexOf("@") >= 0
            let params = {
                email: emailOrPhone,
                phoneNumber: emailOrPhone,
                password,
            }
            if (isEmail == true) {
                delete params.phoneNumber
            } else if (!isEmail) {
                delete params.email
                //delete params.password
            }
            sbSignIn(params)
                .then(async function (data) {
                    //token varsa
                    if (data && data.data && data.data.token) {
                        let firebaseToken = data.data.token
                        dispatch({
                            type: SIGN_IN_SB_SUCCESS,
                            data
                        })
                        console.log("signIn emailSignIn success now calling signInWithCustomToken with firebaseToken")
                        const firebase = await loadFirebase()
                        firebase.auth().signInWithCustomToken(firebaseToken).then(p => {
                            dispatch({
                                type: VALUE_CHANGED,
                                key: "uid",
                                value: p.user.uid
                            })
                            let db = firebase.firestore()
                            db.collection("users").get().then((querySnapshot) => {
                                console.log("querySnapshot is", querySnapshot)
                                querySnapshot.forEach((doc) => {
                                    if (doc.id == p.user.uid) {
                                        dispatch({
                                            type: "user/VALUE_CHANGED",
                                            key: "user",
                                            value: doc.data()
                                        })
                                    }
                                    console.log(`${doc.id} => ${doc.data()}`)
                                })
                            })
                            //logout 'da unlisten etmek lazim , onSnapshot referrans veriyor...
                            unsubscribe = db.collection("users").doc(p.user.uid)
                                .onSnapshot(function (doc) {
                                    dispatch({
                                        type: "user/VALUE_CHANGED",
                                        key: "user",
                                        value: doc.data()
                                    })
                                })
                            resolve(data)
                        }).catch(function (error) {
                            console.log("signIn-signInWithCustomToken hata", error)
                            resolve(error)
                        })
                    }
                    //token yoksa challenge
                    else {
                        dispatch({
                            type: CHALLENGE_CHANGED,
                            data: {
                                code: data?.data?.code,
                                challengeId: data?.data?.challengeId,
                                message: data?.data?.message,
                                challengeInputType: data?.data?.challengeInputType,
                                userId: data?.data?.userId,
                                stage: data?.data?.stage
                            }
                        })
                        dispatch({
                            type: VALUE_CHANGED,
                            key: "challenge",
                            value: data.data
                        })
                        resolve(data)
                    }
                })
                .catch(function (error) {
                    console.log("signIn redux catch is", error)
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                        //console.log(error.response.data)
                        /* console.log(error.response.status)
                        console.log(error.response.headers) */
                    } else {
                        alert("Hata Oluştu")
                    }
                    dispatch({
                        type: SIGN_IN_SB_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const signUp = () => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: SIGN_UP_SB,
            })

            let params = getState().auth.newUser
            //pef name secmedi ise kutudan , ben ilk ismi gonderiyorum..
            if (!params.preferredName && params.firstName) {
                params.preferredName = params.firstName.split(" ")[0]
            }
            sbSignUp(params)
                .then(function (data) {
                    if (data && data.data && data.data.challengeId) {
                        dispatch({
                            type: CHALLENGE_CHANGED,
                            data: {
                                code: data?.data?.code,
                                challengeId: data?.data?.challengeId,
                                message: data?.data?.message
                            }
                        })
                        dispatch({
                            type: VALUE_CHANGED,
                            key: "challenge",
                            value: data.data
                        })
                    } else {
                        if (data && data.data && data.data.message) {
                            console.log("signUp else")
                        }
                    }
                    resolve(data)
                })
                .catch(function (error) {
                    console.log("signUp redux catch is", error)
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    } else {
                        alert("Hata Oluştu")
                    }
                    dispatch({
                        type: SIGN_UP_SB_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const resetPassword = (emailOrPhone) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: RESET_PASSWORD_SB,
            })
            let isEmail = emailOrPhone.indexOf("@") >= 0
            let params = {
                email: emailOrPhone,
                phoneNumber: emailOrPhone,
            }
            if (isEmail == true) {
                delete params.phoneNumber
            } else if (!isEmail) {
                delete params.email
            }
            sbForgotPassword(params)
                .then(function (data) {
                    //burda data.data.challengeId'ye bakmıyorum cunku oncesinde challenge yok
                    if (data.data) {
                        dispatch({
                            type: VALUE_CHANGED,
                            key: "challenge",
                            value: data.data
                        })
                    }
                    resolve(data)
                })
                .catch(function (error) {
                    console.log("redux sbForgotPassword catch is", error)
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    } else {
                        alert("Hata Oluştu")
                    }
                    dispatch({
                        type: RESET_PASSWORD_SB_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const addSbCard = (params) => {
    console.log("params is", params)

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            dispatch({
                type: ADD_SBCARD,
            })

            sbAddSbCard(params, dispatch)
                .then(function (data) {
                    resolve(data)
                })
                .catch(function (error) {
                    console.log("addSbCard redux catch is", error)
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    }
                    /* else {
                                           alert("Starbucks Card Ekleme Hata Oluştu")
                                       } */
                    dispatch({
                        type: ADD_SBCARD_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const createSbCard = (params) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            dispatch({
                type: CREATE_SBCARD,
            })

            sbCreateSbCard(params)
                .then(function (data) {
                    resolve(data)
                })
                .catch(function (error) {
                    //buraya dusmuyor cunku hep resolve() ediyorum genede bulunsun console.log
                    console.log("sbCreateSbCard error is", error)
                })
        })
    }
}

const reNewSbCard = (params) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            dispatch({
                type: RENEW_SBCARD,
            })

            sbRenewSbCard(params, dispatch)
                .then(function (data) {
                    resolve(data)
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    }
                    dispatch({
                        type: RENEW_SBCARD_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const updateProfile = (params) => {

    return async function (dispatch, getState) {
        if (getState().auth.challenge?.stage === 'NEW') {
            try {
                if (params.password) {
                    // update Password
                    const { data } = rioUpdatePassword(getState().user?.user?.userId, params);
                    if (data) {
                        return data;
                    }
                }
            } catch (error) {
                if (error?.response) {
                    if (error?.response?.data) {
                        alert(error.response.data.message)
                    }
                } else {
                    alert("updateProfile Hata Oluştu")
                }
                dispatch({
                    type: UPDATE_PROFILE_FAIL,
                    data: error?.response && error?.response?.data
                })
                return null;
            }
            
        } else {
            return new Promise((resolve, reject) => {
                dispatch({type: UPDATE_PROFILE})

                sbUpdateProfile(params, dispatch)
                    .then(function (data) {
                        if (data && data.data && data.data.challengeId) {
                            dispatch({
                                type: CHALLENGE_CHANGED,
                                data: {
                                    code: data?.data?.code,
                                    challengeId: data?.data?.challengeId,
                                    message: data?.data?.message
                                }
                            })
                        }
                        resolve(data)
                    })
                    .catch(function (error) {
                        console.log("updateProfile redux catch is", error)
                        if (error.response) {
                            if (error.response.data) {
                                alert(error.response.data.message)
                            }
                        } else {
                            alert("updateProfile Hata Oluştu")
                        }
                        dispatch({
                            type: UPDATE_PROFILE_FAIL,
                            data: error.response && error.response.data
                        })
                        reject(error)
                    })
            })
        }
        
    }
}

const updateFavorite = (params) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            dispatch({
                type: UPDATE_FAVORITE
            })

            sbUpdateFavorite(params, dispatch)
                .then(function (data) {
                    resolve(data)
                })
                .catch(function (error) {
                    console.log("updateFavorite redux catch is", error)
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    }
                    dispatch({
                        type: UPDATE_FAVORITE_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const manageAutoReload = (params) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            dispatch({
                type: AUTO_RELOAD
            })

            sbManageAutoReload(params, dispatch)
                .then(function (data) {
                    resolve(data)
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    }
                    dispatch({
                        type: AUTO_RELOAD_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const resendConfirmationCode = (params) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            dispatch({
                type: RESEND_CONFIRMATION_CODE
            })

            sbResendConfirmationCode(params, dispatch)
                .then(function (data) {
                    resolve(data)
                })
                .catch(function (error) {
                    console.log("sbResendConfirmationCode redux catch is", error)
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    }
                    dispatch({
                        type: RESEND_CONFIRMATION_CODE_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const userAnswerChallenge = (challengeAnswer) => {

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: USER_ANSWER_CHALLENGE,
            })
            let params = {
                challengeId: getState().auth.challengeId,
                challengeAnswer
            }
            console.log("redux userAnswerChallenge params is", params)
            sbUserAnswerChallenge(params, dispatch)
                .then(function (data) {
                    console.log("redux userAnswerChallenge then is", data)
                    //token varsa
                    if (data && data.data && data.data.token) {
                        let firebaseToken = data.data.token
                        dispatch({
                            type: USER_ANSWER_CHALLENGE_SUCCESS,
                            data
                        })
                        console.log("emailSignIn success now calling signInWithCustomToken with firebaseToken")
                        firebase.auth().signInWithCustomToken(firebaseToken).then(p => {
                            dispatch({
                                type: VALUE_CHANGED,
                                key: "uid",
                                value: p.user.uid
                            })
                            let db = firebase.firestore()
                            db.collection("users").get().then((querySnapshot) => {
                                console.log("querySnapshot is", querySnapshot)
                                querySnapshot.forEach((doc) => {
                                    if (doc.id == p.user.uid) {
                                        dispatch({
                                            type: "user/VALUE_CHANGED",
                                            key: "user",
                                            value: doc.data()
                                        })
                                    }
                                    console.log(`${doc.id} => ${doc.data()}`)
                                })
                            })
                            //logout 'da unlisten etmek lazim , onSnapshot referrans veriyor...
                            unsubscribe = db.collection("users").doc(p.user.uid)
                                .onSnapshot(function (doc) {
                                    //console.log("Current data: ", doc.data())
                                    dispatch({
                                        type: "user/VALUE_CHANGED",
                                        key: "user",
                                        value: doc.data()
                                    })
                                })
                            resolve(data)
                        }).catch(function (error) {
                            console.log("userAnswerChallenge-signInWithCustomToken hata", error)
                            resolve(data)
                        })
                    }
                    //token yoksa challenge
                    else {
                        /* dispatch({
                            type: CHALLENGE_CHANGED,
                            data: {
                                code: data.data.code,
                                //challengeId: data.data.challengeId,
                                //code 49 durumunda backend null gonderdigi icin challengeId kaybediliyor overwrite  etmemesi icin kontrol.
                                challengeId: (data.data.code != 48 && data.data.code != 49 && data.data.code != 98) ? data.data.challengeId : getState().auth.challengeId,
                                message: data.data.message,
                                challengeInputType: (data.data.code != 48 && data.data.code != 49 && data.data.code != 98) ? data.data.challengeInputType : getState().auth.challengeInputType,
                                userId: (data.data.code != 48 && data.data.code != 49 && data.data.code != 98) ? data.data.userId : getState().auth.userId,
                            }
                        }) */
                        resolve(data)
                    }
                })
                .catch(function (error) {
                    console.log("redux userAnswerChallenge catch is", error)
                    if (error.response) {
                        if (error.response.data) {
                            alert(error.response.data.message)
                        }
                    } else {
                        alert("Hata Oluştu")
                    }
                    dispatch({
                        type: USER_ANSWER_CHALLENGE_FAIL,
                        data: error.response && error.response.data
                    })
                    reject(error)
                })
        })
    }
}

const answerChallenge = (challengeParams, payload) => {
    //console.log("challengeParams is",challengeParams)
    //console.log("payload is",payload)
    //return

    return async function (dispatch, getState) {
        if (getState().auth.challenge?.stage === 'NEW') {
            
                // new istek
                const { userId, challengeId } = getState().auth.challenge || {};
                dispatch({
                    type: VALUE_CHANGED,
                    key: "uid",
                    value: userId
                });
                const answer = challengeParams.challengeAnswer;
                const { data } = await rioAnswerChallange(userId, challengeId, answer);
                if (data?.token) {
                    let culture = Cookies.get("preferredLocale") == "en" ? "en-us" : "tr-tr"
                    const rio = Rio.getInstance({
                        url: "api.sbuxtr.com",
                        projectId : isProd()?"asnt778q":"g7r5gvep",
                        culture,
                      })
                    await rio.authenticateWithCustomToken(data?.token);
                    const response = await rioGetUserInfo(userId);
                    const user = {
                        user: response?.data.userInfo,
                    }
                    dispatch({
                        type: "user/VALUE_CHANGED",
                        key: "user",
                        value: user
                    });
                    return { data: data };
                }

        } else {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: ANSWER_CHALLENGE,
                })
                let params = {
                    challengeId: getState().auth.challengeId,
                    ...challengeParams
                }
                sbSAnswerChallenge(params)
                    .then(async function (data) {
                        //!token varsa
                        if (data && data.data && data.data.token) {
                            let firebaseToken = data.data.token
                            dispatch({
                                type: ANSWER_CHALLENGE_SUCCESS,
                                data
                            })
                            if (payload && payload.ref == "ticimax") {
                                let resp = await ticimaxSignUp(firebaseToken)
                                payload.token = resp?.data?.customToken
                                resolve(payload)
                                return
                            }
                            //console.log("answerChallenge success , calling signInWithCustomToken with firebaseToken")
                            const firebase = await loadFirebase()
                            firebase.auth().signInWithCustomToken(firebaseToken)
                                .then(p => {
                                    dispatch({
                                        type: VALUE_CHANGED,
                                        key: "uid",
                                        value: p.user.uid
                                    })
                                    let db = firebase.firestore()
                                    db.collection("users").get().then((querySnapshot) => {
                                        console.log("querySnapshot is", querySnapshot)
                                        querySnapshot.forEach((doc) => {
                                            if (doc.id == p.user.uid) {
                                                dispatch({
                                                    type: "user/VALUE_CHANGED",
                                                    key: "user",
                                                    value: doc.data()
                                                })
                                            }
                                            console.log(`${doc.id} => ${doc.data()}`)
                                        })
                                    })
                                    //logout 'da unlisten etmek lazim , onSnapshot referrans veriyor...
                                    unsubscribe = db.collection("users").doc(p.user.uid)
                                        .onSnapshot(function (doc) {
                                            //console.log("Current data: ", doc.data())
                                            dispatch({
                                                type: "user/VALUE_CHANGED",
                                                key: "user",
                                                value: doc.data()
                                            })
                                        })
                                    resolve(data)
                                })
                                .catch(function (error) {
                                    console.log("answerChallenge-signInWithCustomToken hata", error)
                                    resolve(data)
                                })
                        }
                        //!token yoksa challenge , 50'de sadece challengeId var.Haricinde eski challengeId korumak lazım..
                        else {
                            dispatch({
                                type: CHALLENGE_CHANGED,
                                data: {
                                    code: data?.data?.code,
                                    challengeId: (data?.data?.code == 50) ? data.data.challengeId : getState().auth.challengeId,
                                    challengeInputType: (data?.data?.code == 50) ? data.data.challengeInputType : getState().auth.challengeInputType,
                                    message: data?.data?.message,
                                    challengeMessage: data?.data.challengeMessage,
                                    userId: (data?.data?.code == 50) ? data.data?.userId : getState().auth.userId,
                                }
                            })
                            dispatch({
                                type: VALUE_CHANGED,
                                key: "challenge",
                                value: data.data
                            })
                            resolve(data)
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            if (error.response.data) {
                                alert(error.response.data.message)
                            }
                        } else {
                            alert("Hata Oluştu")
                        }
                        dispatch({
                            type: ANSWER_CHALLENGE_FAIL,
                            data: error.response && error.response.data
                        })
                        reject(error)
                    })
            })
        }
    }
}

const starbucksRequest = (reqName, params) => {
    let dispatchType = ""
    let successType = ""
    let url = ""
    let verb = "post"

    if (reqName == "updateFavorite") {
        url = publicRuntimeConfig.SB_AUTH_API + "/updateFavorite"
        dispatchType = UPDATE_FAVORITE
        successType = UPDATE_FAVORITE_SUCCESS
    } else if (reqName == "createPin") {
        url = publicRuntimeConfig.SB_AUTH_API + "/createPin"
        dispatchType = CREATE_PIN
        successType = CREATE_PIN_SUCCESS
    } else if (reqName == "updatePin") {
        url = publicRuntimeConfig.SB_AUTH_API + "/updatePin"
        dispatchType = UPDATE_PIN
        successType = UPDATE_PIN_SUCCESS
    } else if (reqName == "forgotPin") {
        url = publicRuntimeConfig.SB_AUTH_API + "/forgotPin"
        dispatchType = FORGOT_PIN
        successType = FORGOT_PIN_SUCCESS
    }
    else if (reqName == "disablePin") {
        url = publicRuntimeConfig.SB_AUTH_API + "/disablePin"
        dispatchType = DISABLE_PIN
        successType = DISABLE_PIN_SUCCESS
    }

    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {

            dispatch({
                type: dispatchType
            })

            sbRequest(url, params, dispatch, verb)
                .then(function (data) {
                    if (data && data.data && data.data.challengeId) {
                        dispatch({
                            type: CHALLENGE_CHANGED,
                            data: {
                                code: data.data?.code,
                                challengeId: data.data?.challengeId,
                                message: data.data?.message,
                                challengeInputType: data.data?.challengeInputType,
                                userId: data.data?.userId
                            }
                        })

                        dispatch({
                            type: VALUE_CHANGED,
                            key: "challenge",
                            value: data.data
                        })
                    }

                    dispatch({
                        type: successType
                    })
                    resolve(data)
                })
        })
    }
}

export {
    valueChanged,
    newUserValueChanged,
    clearNewUser,
    logout,
    getMasterPassToken,
    getMasterPassTokenAmount,
    signIn, //starbucks firebase token almak icin
    signUp, //starbucks firebase token almak icin
    answerChallenge,
    clearChallange,
    resetPassword,
    createSbCard,
    addSbCard,
    reNewSbCard,
    updateProfile,
    updateFavorite,
    manageAutoReload,
    userAnswerChallenge,
    resendConfirmationCode,
    starbucksRequest
}